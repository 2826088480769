import { I18nHtml } from 'shared'

export default function Banner() {
  return (
    <div className="flex gap-x-6 bg-me-purple-700 mt-[-1.0625rem] relative z-10 mb-4" id="banner">
      <div className="mx-auto px-4 py-2.5 w-full">
        <div className="text-sm/6 text-white flex flex-col md:block">
          <I18nHtml i18nKey="banner.title_html" className="inline" />
          <I18nHtml i18nKey="banner.description_html" className="i18n-html inline md:ml-2" />
        </div>
      </div>
    </div>
  )
}
