import { ApolloProvider } from '@apollo/client'
import 'betterplace-react-components/src/css/share_buttons'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, useLocation } from 'react-router-dom'
import { client, CookieBanner, CssVarSetter, MediaContextProvider, mediaStyles, t } from 'shared'
import Catch from 'shared/components/catch/catch'
import Cookies from 'universal-cookie'
import '../../../../../me-theme/semantic.less'
import Footer from '../footer'
import Header from '../header'
import { TrackingViaProvider, ViewerProvider } from './providers'
import Banner from '../Banner'
import LogoShare from '../../../../assets/images/logo-share.png'

const Framework: React.FC<{}> = ({ children }) => {
  return (
    <ApolloProvider client={client}>
      <TrackingViaProvider>
        <ViewerProvider>
          <MediaContextProvider>
            <BrowserRouter>
              <ScrollToTop>
                <ChunkLoadErrorBoundary>
                  <DefaultSocialSharingMetaTags />
                  <CssVarSetter />
                  <Header />
                  <Banner />
                  <div className="me-fill-viewport-height notranslate">
                    <div id="global-flash-message" />
                    <style>{mediaStyles}</style>
                    {children}
                  </div>
                  <CookieBanner />
                  <Footer />
                </ChunkLoadErrorBoundary>
              </ScrollToTop>
            </BrowserRouter>
          </MediaContextProvider>
        </ViewerProvider>
      </TrackingViaProvider>
    </ApolloProvider>
  )
}
const ScrollToTop: React.FC<{}> = ({ children }) => {
  const location = useLocation()
  useEffect(() => {
    const { keepScrollPos = false } = (location || {}).state || {}
    if (location && !keepScrollPos) window.scrollTo(0, 0)
  }, [location])

  return <>{children}</>
}
// ChunkLoadErrors can happen if the user has an old version of the frontend
// in the browser cache for which we no longer have the referenced chunk files.
// In this case, reloading the page should fix the problem.
const ChunkLoadErrorBoundary = Catch(
  (({ children }) => <>{children}</>) as React.FC,
  (error) => {
    throw error
  },
  // ChunkLoadError is not thrown in child component and therefore will not trigger componentDidCatch.
  (error) => {
    if (error.name !== 'ChunkLoadError') return {}

    // try one page reload; set cookie to avoid reload loop
    const cookies = new Cookies()
    const cookieName = 'chunk-load-retried'
    if (!cookies.get(cookieName)) {
      cookies.set(cookieName, 1, { maxAge: 60, path: window.location.pathname })
      window.location.reload()
    }
    return { error }
  }
)

// can be overridden in full or part by other Helmet tags with deeper nesting
const DefaultSocialSharingMetaTags = () => {
  const hostname = t('hostname')
  const description = t('seo.description')
  const title = t('seo.title')

  return (
    <Helmet>
      <title>{hostname}</title>

      <meta name="description" content={description} />
      <meta name="title" content={title} />

      <meta property="og:description" content={description} />
      <meta property="og:image" content={LogoShare} />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:locale" content="de_DE" />
      <meta property="og:site_name" content={hostname} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:domain" content={`www.${hostname}`} />
      <meta name="twitter:image" content={LogoShare} />
      <meta name="twitter:site" content="@betterplace_org" />
      <meta name="twitter:title" content={title} />
    </Helmet>
  )
}

export default Framework
