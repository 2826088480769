import React, { Suspense, lazy } from 'react'
import { Route, Routes as RRoutes } from 'react-router-dom'
import { GenericNotFound, ProtectedRoute, Routes, AppContext, AppProps } from 'shared'
import Framework from './app/framework'

// Don't touch that (otherwise SUIMenu will throw cryptic errors)
import { Menu } from 'semantic-ui-react'

const CampaignShow = lazy(() => import('./campaign/show'))
const ManageProfile = lazy(() => import('./profile/show'))
const CampaignNew = lazy(() => import('./campaign/new'))
const CampaignManageIndex = lazy(() => import('./campaign/manage'))
const CampaignManageShow = lazy(() => import('./campaign/manage/show'))
const CampaignIndex = lazy(() => import('./campaign'))
const CampaignPoster = lazy(() => import('./campaign/poster'))
const CategoryShow = lazy(() => import('./category/show'))
const SignInForm = lazy(() => import('./session/new'))
const SignOut = lazy(() => import('./session/destroy'))
const SignUpForm = lazy(() => import('./user/new'))
const UserDeleted = lazy(() => import('./user/deleted'))
const PasswordNew = lazy(() => import('./password/new'))
const PasswordEdit = lazy(() => import('./password/edit'))
const ResendConfirmation = lazy(() => import('./confirmation/new'))
const ConfirmEmail = lazy(() => import('./confirmation/show'))
const DestroySubscription = lazy(() => import('./subscription/destroy'))
const DonationDepositIndex = lazy(() => import('./donation/deposit'))
const DonationShow = lazy(() => import('./donation/show'))
const DonationNew = lazy(() => import('./donation/new'))
const Privacy = lazy(() => import('./static/privacy'))
const Usage = lazy(() => import('./static/usage'))
const TermsGeneral = lazy(() => import('./static/terms_general'))
const TermsAdditional = lazy(() => import('./static/terms_additional'))

const App: React.FC<AppProps> = (props) => (
  <Suspense fallback={null}>
    <AppContext.Provider value={{ ...props, appConfigLoading: false }}>
      <Framework>
        <RRoutes>
          {/* Public dynamic pages */}
          <Route path={`${Routes.campaignPath(':slug')}/*`} element={<CampaignShow />} />
          <Route path={Routes.campaignsPath()} element={<CampaignIndex />} />
          <Route path={Routes.campaignPosterPath(':slug')} element={<CampaignPoster />} />
          <Route path={Routes.donationDepositPath(':token')} element={<DonationDepositIndex />} />
          <Route path={`${Routes.donationPath(':token')}/*`} element={<DonationShow />} />
          <Route path={Routes.newDonationPath(':slug')} element={<DonationNew />} />

          {/* Category pages */}
          <Route path={Routes.donateForPath(':slug')} element={<CategoryShow />} />

          {/* Session management */}
          <Route path={Routes.signInPath()} element={<SignInForm />} />
          <Route path={Routes.signOutPath()} element={<SignOut />} />
          <Route path={Routes.signUpPath()} element={<SignUpForm />} />
          <Route path={Routes.userDeletedPath()} element={<UserDeleted />} />
          <Route path={Routes.newPasswordPath()} element={<PasswordNew />} />
          <Route path={Routes.editPasswordPath(':token')} element={<PasswordEdit />} />
          <Route path={Routes.newConfirmationPath()} element={<ResendConfirmation />} />
          <Route path={Routes.confirmationPath(':token')} element={<ConfirmEmail />} />
          <Route path={Routes.unsubscribeNewsPath()} element={<DestroySubscription />} />

          {/* Static pages */}
          <Route path={Routes.privacyPath()} element={<Privacy />} />
          <Route path={Routes.usagePath()} element={<Usage />} />
          <Route path={Routes.termsGeneralPath()} element={<TermsGeneral />} />
          <Route path={Routes.termsAdditionalPath()} element={<TermsAdditional />} />

          {/* Protected dynamic pages */}
          <Route element={<ProtectedRoute />}>
            <Route path={`${Routes.manageProfilePath()}/*`} element={<ManageProfile />} />
            <Route path={Routes.manageCampaignsPath()} element={<CampaignManageIndex />} />
            <Route path={`${Routes.manageCampaignPath(':slug')}/*`} element={<CampaignManageShow />} />
            <Route path={'/:slug/new/*'} element={<CampaignNew />} />
          </Route>

          <Route path={Routes.notFoundPath()} element={<GenericNotFound />} />
          <Route element={<GenericNotFound />} />
        </RRoutes>
      </Framework>
    </AppContext.Provider>
  </Suspense>
)

export default App
