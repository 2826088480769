import { TQuickinfoType } from 'gql'
import { Link } from 'react-router-dom'
import { CookieBannerLink, Quickinfo, Routes, t } from 'shared'
import GoodCrowdLogo from '../../../assets/images/goodcrowd-busy.svg'

const Footer = () => (
  <footer className="me-footer">
    <div className="ui container me-footer--grid">
      <ul className="text-list">
        <li>
          <a href={Routes.aboutUsPath()}>{t('link_titles.about_us')}</a>
        </li>
        <li>
          <a href={Routes.pricingPath()}>{t('link_titles.fees')}</a>
        </li>
        <li>
          <a href={Routes.helpPath()}>{t('link_titles.help')}</a>
        </li>
        <li>
          <Link to={Routes.usagePath()}>{t('link_titles.how_it_works')}</Link>
        </li>
        <li>
          <Link to={Routes.termsGeneralPath()}>{t('link_titles.terms_of_service')}</Link>
        </li>
        <li>
          <Link to={Routes.privacyPath()}>{t('link_titles.privacy_policy')}</Link>
        </li>
        <li>
          <a href={Routes.imprint()}>{t('link_titles.imprint')}</a>
        </li>
        <li>
          <Link to={Routes.termsRevocationPath()}>{t('link_titles.cancellation_policy')}</Link>
        </li>
        <li>
          <a href="/blog">Blog</a>
        </li>
        <li>
          <CookieBannerLink content={t('link_titles.cookie_settings')} />
        </li>
      </ul>

      <img src={GoodCrowdLogo} alt="GoodCrowd.org logo" className="ui image logo" />

      {/*
      // Link goodcrowd accounts once created: https://betterplace.atlassian.net/browse/DEV-25461
      <ul className="icon-list">
        <a className="facebook" href="https://www.facebook.com/betterplace.org">
          <i aria-hidden="true" className="facebook icon" />
        </a>
        <a className="instagram" href="https://www.instagram.com/betterplace_org/">
          <i aria-hidden="true" className="instagram icon" />
        </a>
      </ul>
      */}
      <Quickinfo type={TQuickinfoType.Dev} placement="bottom" />
    </div>
  </footer>
)

export default Footer
