import 'core-js/stable'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime/runtime'
import App from '../application/app'

const COMMIT_HASH = process.env.COMMIT_HASH
const msg = `
Starting GoodCrowd.org application
COMMIT SHA: ${COMMIT_HASH} @ ${new Date().toISOString()}

`
console.log(msg)

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('me-application')
  if (!container) return

  const hostname = container.dataset?.hostname || ''
  const paymentLiveMode = container.dataset?.paymentLiveMode || ''
  const paypal = JSON.parse(container.dataset?.paypal || '{}')
  const stripe = JSON.parse(container.dataset?.stripe || '{}')
  const gtm = JSON.parse(container.dataset?.gtm || '{}')
  const friendlyCaptcha = JSON.parse(container.dataset?.friendlyCaptcha || '{}')
  const posthog = JSON.parse(container.dataset?.posthog || '{}')
  const root = createRoot(container) // createRoot(container!) if you use TypeScript

  root.render(
    <App
      hostname={hostname}
      paypal={paypal}
      paymentLiveMode={paymentLiveMode}
      stripe={stripe}
      gtm={gtm}
      friendlyCaptcha={friendlyCaptcha}
      posthog={posthog}
    />
  )
  // render(el, root)
})
